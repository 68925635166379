html {
  --main-text-color: #282c34;
  --header-main-color: #282c34;
  --header-secondary-color: white;
  --main-bg: #dddddd;
  --board-emphasis-bg: #333333;
  /* --main-bg: #dddddd; */
  /* --main-bg: white; */
  --rule-color: #dddddd;
  --hit-bg: #6aaa64;
  --close-bg: #c9b458;
  --miss-bg: #494d57; /*#8f96aa;*/ /* #888888 */
  --neutral-bg: white; /* #dddddd; 
  --trust-symbol-bg: #a8abb9; /* #aaaaaa; */
  --blank-square-text-color: #282c34; /* black; */
  --blank-unoccupied-border: var(--neutral-bg);
  --blank-occupied-border: #a8abb9; /* #aaaaaa; */
  --past-square-text-color: white;
  --satisfaction-color: #282c34; /* black; */
  --give-up-bg: black;
  --message-bg: black;
  --message-text-color: white;
  --win-bg: var(--hit-bg);
  /* --unexplored-text-color: black; */
  --unexplored-text-color: var(--past-square-text-color);
  --unexplored-text-color-0-2: white;
  --unexplored-text-color-1-2: white;
  --unexplored-bg: #aaaaaa; /*#dbe0f5;*/ /* #d3d3d3; */
  /* --unexplored-bg-0-2: #cc9999;
  --unexplored-bg-1-2: #9999dd; */
  --unexplored-bg-0-2: #aaaaaa;
  --unexplored-bg-1-2: #aaaaaa;
  --unexplored-bg-0-3: #aaaaaa;
  --unexplored-bg-1-3: #aaaaaa;
  --unexplored-bg-2-3: #aaaaaa;
  --hit-text-color: #00000040;
  --close-text-color: #00000040;
  --miss-text-color: #00000060;
  --keyboard-off-text-color: #a8abb9;
  --keyboard-off-bg: #eceffc;
  --link-color: #abb3c7;
  --slash-angle: 112.5deg;
  --keyboard-angle: var(--slash-angle);
}

.high-contrast {
  --hit-bg: #f5793a;
  --close-bg: #85c0f9;
  --win-bg: var(--hit-bg);
}

.dark-mode {
  --header-main-color: white;
  --header-secondary-color: #282c34;
  --main-text-color: #DDDDDD;
  --main-bg: #333333;
  --board-emphasis-bg: #dddddd;
  /* --rule-color: #888888;
  --miss-bg: #686e7c; 
  --trust-symbol-bg: #5e626b;
  --neutral-bg: #999999;
  --blank-square-text-color: white;
  --blank-unoccupied-border: #666666;
  --blank-occupied-border: var(--neutral-bg);
  --past-square-text-color: white;
  --satisfaction-color: black;
  --give-up-bg: black;
  --message-bg: black;
  --message-text-color: white;
  --win-bg: var(--hit-bg);
  --unexplored-text-color: black;
  --unexplored-text-color: var(--past-square-text-color);
  --unexplored-bg: #abb3c7; 
  --keyboard-off-text-color: #7f8491;
  --keyboard-off-bg: #5f636e;
  --link-color: #dbe0f5; */
}

.App {
  text-align: center;
  color: var(--main-text-color);
}

.App-header {
  background-color: var(--main-bg);
  /* transition: background-color 10s; */
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

/* .App-header.hide {
  display: none;
} */

.main-column {
  min-height: 100vh;
  width: 100vw;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}

.game-header, .modal-header {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  flex: 0 0 50px;
  z-index: 1;
}

.game-header {
  border-bottom: 2px solid var(--main-bg);
  position: sticky;
  /* background-color: var(--main-bg); */
  background: linear-gradient(var(--slash-angle), var(--header-main-color) 0%, var(--header-main-color) 50%, var(--header-secondary-color) 50%, var(--header-secondary-color) 100%);
  top:0;
}

.modal-header {
  margin-bottom: 12px;
}

.game-header-title {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 4;
  font-size: 28pt;
  font-weight: bold;
}

.game-header-title.left {
  justify-content: flex-end;
  color: var(--header-secondary-color);
  /* background-color: rgba(0, 0, 255, 0.5); */
}

.game-header-title.left > div {
  padding-right: 0.17em;
}

.game-header-title.right {
  justify-content: flex-start;
  color: var(--header-main-color);
  /* background-color: rgba(255, 0, 0, 0.5); */
}

.game-header-title sup {
  font-size: 18pt;
}

.modal-title {
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  justify-content: center;
  align-items: center;
  flex: 4;
  font-size: 16pt;
  font-weight: bold;
}

.game-header-button, .modal-close-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 50px;
  overflow: hidden;
  background: none;
  border: 0;
  font-size: 18pt;
  font-weight: bold;
  color: var(--main-text-color);
}

.game-header svg {
  fill: var(--header-main-color);
  flex: 1;
  min-width: 22px;
  min-height: 22px;
}

.game-header-button.left svg {
  fill: var(--header-secondary-color);
  /* transform: scale(10, 10); */
}

/* .modal-close-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0;
  font-size: 18pt;
  font-weight: bold;
} */

.main-area{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin: 4px 0 0 0;
}

.game-area, .gameboard-explainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  /* margin: 6px 0 2px 0; */
  margin: 2px 0 2px 0;
}

.gameboard-explainer {
  margin: 8px 0;
  align-self: center;
  background-color: var(--main-bg);
}

.gameboard-block { /* mirrors .game-area */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin: 2.5px 0 6.5px 0;
  gap: 0;
}

.gameboard-fullrow-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.gameboard-fullrow { /* mirrors old .gameboard-block */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  margin: 0 1px 0 1px;
}

/*.gameboard-block,*/ .mini-keyboard-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* gap: 12px;
  margin: 0 5px 8px 5px; */
  gap: 4px;
  margin: 0 1px 4px 1px;
}

.mini-keyboard-block {
  margin-bottom: 0px;
}

.gameboard {
  display: flex;
  flex-direction: column;
  gap: 3px;
  width: 100%;
  max-width: 400px;
  padding: 4px;
  /* margin: 8px 0 2px 0; */
}

/* .gameboard-row-wrapper:has(.emphasized) { */
    /* background-color: var(--board-emphasis-bg); */
  /* box-shadow: inset 0 0 3px 0px blue; */
/* } */

.gameboard-row-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  /* added: */
  width: 100%;
  max-width: 400px;
  padding: 1.5px 4px;
}

.gameboard-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-gap: 3px;
}


.gameboard-row.hidden {
  visibility: hidden;
}

.progress-bar {
  margin: 6px 0px 2px 0px;
  height: 10px;
  border-radius: 5px;
}

.gameboard-row.distrust {
  opacity: 45%;
}

.gameboard-square-wrapper {
  aspect-ratio: 1 / 1;
  max-height: 10vh;
  max-width: 10vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  position: relative;
}

.gameboard-square {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  font-size: 140%;
  /* font-size: 15pt; */
  /* font-size: 12pt; */
  font-weight: bold;
  padding: 0;
  overflow: hidden;
  position:absolute;
  width: 100%;
  height: 100%;
}

/* .gameboard-square-back-wrapper {
  position:absolute;
  width: 100%;
  height: 100%;
  background:red;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
} */


.ghost .gameboard-square-wrapper {
  filter: blur(2px);
  opacity: 60%;
  /* transform: scale(0.75, 0.75); */
}

.emphasized .gameboard-square-wrapper {
  /* box-shadow: 0 0 3px 1px var(--main-bg); */
  /* box-shadow: 0 0 0px 1px var(--board-emphasis-bg); */
  /* box-shadow: 0 0 3px 1px var(--hit-bg); */
  box-shadow: 0 0 3px 1px #5d89b5;
  /* transform: rotate3d(1, 1, 0, 360deg); */
}

.gameboard-square-inner {
  display: flex;
  flex: 1;
  margin: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.gameboard-square.hit {
  color: var(--past-square-text-color);
  background-color: var(--hit-bg);
  /* transition: transform 2s; */
}

/* .gameboard-square.hit:hover {
  transform: translateX(42px) translateY(84px);
} */

.gameboard-square.close {
  color: var(--past-square-text-color);
  background-color: var(--close-bg);
}

.gameboard-square.miss {
  color: var(--past-square-text-color);
  background-color: var(--miss-bg);
}

.gameboard-square.blank {
  color: var(--blank-square-text-color);
  /* box-shadow: inset 0 0 3px 2px red; */
}

.gameboard-square.neutral {
  /* color: var(--blank-square-text-color); */
  /* background-color: var(--neutral-bg); */
  background-color: var(--unexplored-bg);
}

.gameboard-square.neutral .gameboard-square-inner {
  border: 2px solid var(--unexplored-bg);
}

.gameboard-square.blank {
  background-color: var(--blank-unoccupied-border);
  /* padding: 0.8%; */
}

.gameboard-square.blank .gameboard-square-inner {
  /* border: 2px solid var(--blank-unoccupied-border); */
  border: 2px solid var(--unexplored-bg);
}

@keyframes type-letter {
  from {}
  to {transform: scale(1.1, 1.1);}
}

/* .gameboard-square-wrapper:has(.gameboard-square.blank.occupied.should-animate-typed-letter) { */
.gameboard-square-wrapper.should-animate-typed-letter {
  /* transform: scale(1.2, 1.2); */
  animation-name: type-letter;
  animation-duration: 0.1s;
  animation-timing-function: linear;
  animation-iteration-count: 2;
  animation-direction: alternate;
}

.gameboard-square.blank.occupied .gameboard-square-inner {
  border-width: 4px;
}

/* .gameboard-square.blank.occupied .gameboard-square-inner {
  border-color: var(--blank-occupied-border);
} */

.gameboard-square.blank.occupied.hint-hit .gameboard-square-inner {
  border-color: var(--hit-bg);
  color: var(--hit-bg);
}

.gameboard-square.blank.occupied.hint-close .gameboard-square-inner {
  border-color: var(--close-bg);
  color: var(--close-bg);
}

.gameboard-square.blank.occupied.hint-miss .gameboard-square-inner {
  border-color: var(--miss-bg);
  color: var(--miss-bg);
}

.gameboard-square.blank.occupied.hint-unexplored .gameboard-square-inner {
  border-color: var(--unexplored-bg);
  color: var(--unexplored-bg);
}

.gameboard-square.unsatisfied .gameboard-square-inner {
  /* margin: 2px; */
  /* border: 6px solid black; */
  color: var(--satisfaction-color);
  /* border-radius: 50%; */
}

.gameboard-row.given-up .gameboard-square {
  border: 0;
  background-color: var(--give-up-bg);
  color: var(--past-square-text-color);
}

.gameboard-row.given-up .gameboard-square .gameboard-square-inner {
  border: 0;
}

.gameboard-row-wrapper:has(.given-up.should-animate-given-up) {
  animation-name: given-up-show;
  animation-duration: var(--given-up-duration);
  animation-timing-function: linear;
  /* animation-fill-mode: backwards; */
}

.trust-button {
  aspect-ratio: 1 / 2;
  max-height: 10vh;
  max-width: 5vh;
  display: flex;
  flex-direction: column;
  flex: 0.5;
  justify-content: center;
  align-items: center;
  background: none;
  color: var(--trust-symbol-bg);
  border: 0;
  font-size: 24px;
  font-weight: bold;
  padding: 0;
  cursor: pointer;
  overflow: hidden;
}

.trust-button.arrow {
  color: var(--main-text-color);
  font-size: 30px;
}

.trust-button.arrow svg {
  fill: var(--main-text-color);
}

.trust-button .margin-small {
  font-size: 12px;
  margin-bottom: -2px;
  line-height: 1;
}

.turn-counter {
  display: flex;
  align-self: center;
  color: var(--unexplored-bg);
  font-size: 14pt;
  font-weight: bold;
  margin: 0 0 0 0;
  letter-spacing: 0.5em;
}

.message {
  display: flex;
  align-self: center;
  /* flex: 0.5; */
  height: 1.5em;
  justify-content: center;
  align-items: center;
  background-color: var(--message-bg);
  color: var(--message-text-color);
  border-radius: 4px;
  font-size: 12pt;
  padding: 4px 12px;
  margin: 4px 0 0 0;
}

.message.max-lies-add-message {
  margin-top: 10px;
}

.message.win {
  background-color: var(--win-bg);
}


/* begin clue animation */

/* .gameboard-square.backside {
  display: none;
} */

.gameboard-fullrow {
  perspective: 1000px;
  transform-style: preserve-3d;
}

.gameboard-row-wrapper:has(.gameboard-row.should-animate-clue-flip), 
.gameboard-row-wrapper:has(.gameboard-row.should-animate-clue-fade) {
  perspective: inherit;
  transform-style: preserve-3d;
}

.gameboard-row.should-animate-clue-flip,
.gameboard-row.should-animate-clue-fade {
  perspective: inherit;
  transform-style: preserve-3d;
}

.gameboard-square-wrapper {
  /* perspective: 1000px; */
  perspective: inherit;
  transform-style: preserve-3d;
}

.gameboard-square {
  transition: transform 0.5s;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.gameboard-row.should-animate-clue-flip .gameboard-square.backside {
  transform: rotateX(180deg);
}

.gameboard-row.should-animate-clue-fade .gameboard-square.backside {
  /* opacity: 0.5; */
  /* transform: scale(0.5, 0.5); */
  z-index: 1;
}

/* .gameboard-row.should-animate-clue-fade .gameboard-square.frontside {
  z-index: 1;
} */

.gameboard-row.should-animate-clue-fade {
  --changing-opacity: 1;
}

.gameboard-row.should-animate-clue-fade .gameboard-square.backside {
  --changing-opacity: 0;
}

.gameboard-square {
  --changing-opacity: 1;
}

.gameboard-square.neutral {
  --changing-opacity: 0;
}

/* 
@keyframes in-out {
  0% {transform: translate3d(0, 0, 0)}
  80% {transform: translate3d(0, 0, -3000px);}
  100% {transform: translate3d(0, 0, 0);}
} */

@keyframes in-out {
  0% {transform: scale(1, 1);}
  70% {transform: scale(0.3, 0.3);}
  100% {transform: scale(1, 1);}
}

@keyframes fade-out-neutral {
  0% {}
  70% {opacity: 1;}
  100% {opacity: var(--changing-opacity);}
}

@keyframes given-up-show {
  0% {opacity: 0%}
  100% {opacity: 100%}
}


html {
  --in-out-wait: 0s;
  --in-out-duration: 0.3s;
  --flip-wait: 0.3s;
  --flip-duration: 0.3s;
  --given-up-duration: 1s;
}


@keyframes vertical-flip {
  0% {transform: rotateX(180deg);}
  100% {transform: rotateX(0deg);}
}

.gameboard-square-wrapper:nth-child(1) {--n: 1}
.gameboard-square-wrapper:nth-child(2) {--n: 2}
.gameboard-square-wrapper:nth-child(3) {--n: 3}
.gameboard-square-wrapper:nth-child(4) {--n: 4}
.gameboard-square-wrapper:nth-child(5) {--n: 5}

.gameboard-row.should-animate-clue-flip .gameboard-square-wrapper {
  animation-name: vertical-flip;
  animation-duration: var(--flip-duration);
  animation-timing-function: linear;
  /* animation-delay: 0.7s; */
  animation-delay: calc(var(--flip-duration) * (var(--n) - 1) + var(--in-out-wait) + var(--in-out-duration) + var(--flip-wait));
  /* animation: vertical-flip 0.8s linear 0.7s; */
  animation-fill-mode: backwards;
}

/* .gameboard-row.should-animate-clue-flip .gameboard-square-wrapper:nth-child(1) {
  animation-delay: 0.7s;
}

.gameboard-row.should-animate-clue-flip .gameboard-square-wrapper:nth-child(2) {
  animation-delay: 1.2s;
} */

.gameboard-row-wrapper:has(.gameboard-row.should-animate-clue-flip), 
.gameboard-row-wrapper:has(.gameboard-row.should-animate-clue-fade) {
  animation-name: in-out;
  animation-duration: var(--in-out-duration);
  animation-timing-function: linear;
  /* animation-fill-mode: backwards; */
}

.gameboard-row.should-animate-clue-fade .gameboard-square.backside {
  animation: fade-out-neutral var(--in-out-duration) linear 0s;
  animation-fill-mode: forwards;
}

@keyframes next-row {
  0% {opacity: 0%;}
  50% {opacity: 0%;}
  100% {}
}

.gameboard-row.should-animate-next-row, .game-over-row.should-animate-game-over-row, .message.should-animate-game-over-row {
  animation-name: next-row;
  /* animation-duration: 2s; */
  animation-timing-function: linear;
  /* animation-delay: calc(var(--in-out-wait) + var(--in-out-duration) + var(--flip-wait) + 4 * var(--in-out-duration) + 0.2s); */
  animation-delay: 0.2s;
  animation-duration: 0.4s;
  animation-fill-mode: backwards;
}

/* end clue animation */


/* moral */

.eliminated-option {
  text-decoration: line-through;
}

.letter-progress-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: stretch;
  padding: 8px 0 8px 0;
}

.letter-progress-card {
  /* color: var(--unexplored-text-color); */
  /* background-color: var(--unexplored-bg); */
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1px 2px 3px 5px;
  border-bottom: solid 1px #aaaaaa;
}

.letter-progress-tile {
  aspect-ratio: 1;
  height: 25px;
  min-height: 25px;
  min-width: 25px;
  max-height: 8vh;
  color: var(--unexplored-text-color);
  /* background-color: var(--miss-bg); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 120%;
  font-weight: bold;
  padding: 0;
  overflow: hidden;
  margin: 3px 0;
}

/* .letter-progress-tile.close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) 10%, var(--hit-bg) 50%);
} */

.letter-progress-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: center;
}

/* end moral */

.keyboard-area {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  gap: 8px;
  padding: 8px 0 0 0;
  background-color: var(--main-bg);
}

.keyboard, .mini-keyboard {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  /* gap: 8px; */
  /* padding: 0 0 12px 0;
  background-color: var(--main-bg); */
}

.keyboard {
  max-height: 40vh;
  flex: 0 190px;
  padding: 0 0 12px 0;
  gap: 8px;
  background-color: var(--main-bg);
}

.mini-keyboard {
  max-height: 20vh;
  flex: 0 60px;
  padding: 0 0 0 0;
  gap: 2px;
  background-color: #80808040; /* var(--main-bg); */
  border-radius: 8px;
}

.keyboard > *, .mini-keyboard > * {
  flex: 1;
  /* height: 58px; */
}

.keyboard-row, .mini-keyboard-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}

.keyboard-row {
  gap: 4px;
}

.mini-keyboard-row {
  gap: 0px;
}

.keyboard-button, .mini-keyboard-button, .trust-symbol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: 14pt; */
  font-weight: bold;
  color: var(--unexplored-text-color);
  /* border-radius: 4px; */
  border: 0;
  padding: 0;
  overflow: hidden;
}

.keyboard-button, .trust-symbol {
  position:relative;
  border-radius: 4px;
  font-size: 14pt;
}

.mini-keyboard-button {
  font-size: 14pt;
}


.keyboard-button.letter {
  flex: 0 1 calc(10% - 4px);
  /* text-shadow: 0 0 3px var(--miss-bg); */
}
.mini-keyboard-button.letter {
  flex: 0 1 calc(10% - 0px);
  /* text-shadow: 0 0 3px black; */
}

/* .keyboard-button .text-central {
  position: relative;
  font-size: 120%;
} */

/* .keyboard-button .text-0-2 {
  position: relative;
  /* left: -5px;
  top:-50%; 
}

.keyboard-button .text-1-2 {
  position: relative;
  /* left: -20%;
  top:-30%; 
  /* left: calc(-5px);
  top: -50%; 
  /* right:-10%;
  bottom:-10px; 
  /* display:none; 
} */

/* .keyboard-button .text-0-2.unexplored {
  color: var(--unexplored-text-color-0-2);
}

.keyboard-button .text-1-2.unexplored {
  color: var(--unexplored-text-color-1-2);
} */

.keyboard-button .text-hit {
  color: var(--hit-text-color);
  display: none;
}

.keyboard-button .text-close {
  color: var(--close-text-color);
  display: none;
}

.keyboard-button .text-miss {
  color: var(--miss-text-color);
  display: none;
}

.keyboard-button svg {
  fill: var(--unexplored-text-color);
}

.trust-symbol.confident {
  aspect-ratio: 2 / 3;
  height: 55%;
  background-color: var(--trust-symbol-bg);
  color: var(--past-square-text-color);
}
.trust-symbol.unsure {
  aspect-ratio: 1;
  height: 35%;
  border-radius: 50%;
  background-color: var(--trust-symbol-bg);
  color: var(--past-square-text-color);
}
.trust-symbol.distrust {
  aspect-ratio: 2 / 3;
  height: 50%;
  color: var(--trust-symbol-bg);
}

.keyboard-button.hit, .keyboard-button-inner.hit {
  background-color: var(--hit-bg);
  color: var(--past-square-text-color);
}
.mini-keyboard-button.hit {
  background-color: none;
  color: var(--hit-bg);
}
.keyboard-button.close, .keyboard-button-inner.close {
  background-color: var(--close-bg);
  color: var(--past-square-text-color);
}
.mini-keyboard-button.close {
  background-color: none;
  color: var(--close-bg);
}
.keyboard-button.miss, .keyboard-button-inner.miss {
  background-color: var(--miss-bg);
  color: var(--past-square-text-color);
}
.mini-keyboard-button.miss {
  visibility:hidden;
}
.keyboard-button.unexplored, .keyboard-button-inner.unexplored {
  background-color: var(--unexplored-bg);
  /* background: linear-gradient(to right, red 0%, red 50%, blue 50%, blue 100%); */
  color: var(--unexplored-text-color);
}
.mini-keyboard-button.unexplored {
  background-color: none;
  color: var(--unexplored-bg);
}
/* .mini-keyboard-button.untouched {
  background-color: none;
  color: var(--main-text-color);
} */

/* .keyboard-button.close-unexplored, .keyboard-button-inner.close-unexplored {
  background: linear-gradient(to right, var(--close-bg) 0%, var(--close-bg) 50%, rgba(127, 127, 127, 0) 50%, rgba(127, 127, 127, 0) 100%),
              linear-gradient(to right, rgba(127, 127, 127, 0) 0%, rgba(127, 127, 127, 0) 50%, var(--unexplored-bg) 50%, var(--unexplored-bg) 100%);
  color: var(--unexplored-text-color);
} */

/* .keyboard-button.close-unexplored, .keyboard-button-inner.close-unexplored {
  background: linear-gradient(to right, var(--close-bg) 0%, var(--close-bg) 50%, var(--unexplored-bg) 50%, var(--unexplored-bg) 100%);
  color: var(--unexplored-text-color);
} */

.keyboard-button-inner {
  display: flex;
  width: 29px;
  aspect-ratio: 1;
  /* flex: 1; */
  /* margin: 5px; */
  /* align-self: center; */
  padding: 0 0 0 0;
  /* margin: 14px 0 14px 0; */
  justify-content: center;
  align-items: center;
  /* background-color: #aaaaaa80; */
  /* border-radius: 4px 4px 0 0; */
  border-radius: 50%;
}

/* .important-unexplored-list-0-1 > .keyboard-button-inner { */
  /* color: white; */
/* } */

/* .important-unexplored-list-0 > .keyboard-button-inner {
  color: #ffcccc; */
  /* background-color:  #cc999980; */
/* } */

/* .important-unexplored-list-1 > .keyboard-button-inner {
  color: #ccffff; */
  /* background-color:  #9999dd80; */
/* } */

/* .important-unexplored-list > .keyboard-button-inner {
  color: #bbbbbb; */
  /* background-color: #33333380; */
/* } */

/* .important-unexplored-list */

/* .keyboard-button-inner {
  display: flex;
  flex: 1;
  padding: 0 0 0 0;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 5px;
  align-self: stretch;
  background-color: var(--unexplored-bg);
} */

/* .keyboard-button-inner {
  position: absolute;
  left: calc(50% - 14.5px);
  top: calc(50% - 14.5px);
} */

/* .keyboard-button-inner positioning */

/* .keyboard-button-inner {
  position: absolute;
} */

/* .keyboard-button-inner.central { */
  /* font-size: 120%; */
  /* left: calc(50% - 14.5px);
  top: calc(50% - 14.5px);
} */
/* .keyboard-button-inner.major-minor-0 { */
  /* font-size: 120%; */
  /* left: calc(50% - 14.5px);
  top: calc(50% - 14.5px);
} */
/* .keyboard-button-inner.minor-major-1 { */
  /* font-size: 120%; */
  /* left: calc(50% - 14.5px);
  top: calc(50% - 14.5px);
} */

/* .keyboard-button-inner.major-major-0, .keyboard-button-inner.minor-minor-0 { */
  /* left: calc(30% - 14.5px);
  top: calc(25% - 14.5px); */
  /* font-size: 120%; */
  /* left: calc(50% - 14.5px);
  top: calc(50% - 14.5px);
} */

/* .keyboard-button-inner.major-major-1, .keyboard-button-inner.minor-minor-1 { */
  /* left: calc(70% - 14.5px);
  top: calc(75% - 14.5px); */
  /* font-size: 120%; */
  /* left: calc(50% - 14.5px);
  top: calc(50% - 14.5px);
} */

/* .keyboard-button-inner.central coloring */

/* .keyboard-button.all-unexplored {
  background: var(--unexplored-bg);
} */

/* .keyboard-button.all-unexplored > .keyboard-button-inner.central {
  color: var(--unexplored-text-color); */
  /* text-shadow: 0 0 4px white; */
  /* text-shadow: -2px -2px 5px red, 3px 3px 5px blue; */
  /* text-shadow: -1px -1px 3px #cc88ff, 1px 1px 3px #cc88ff; */
  /* text-shadow: -2px -2px 5px #666666, 3px 3px 5px #666666; */
/* } */

/* .keyboard-button.all-miss > .keyboard-button-inner.central { */
  /* color: var(--miss-text-color); */
  /* color: var(--unexplored-text-color);
} */

/* .keyboard-button-inner.central, .keyboard-button-inner.major-minor-0, .keyboard-button-inner.minor-major-1 { */
  /* font-size: 120%; */
/* } */

/* .keyboard-button.all-mixed > .keyboard-button-inner.central, .keyboard-button-inner.major-minor-1, .keyboard-button-inner.minor-major-0 {
  display: none;
} */

/* .keyboard-button.all-unexplored > .keyboard-button-inner.single, .keyboard-button.all-miss > .keyboard-button-inner.single {
  display: none;
} */

/* .keyboard-button-inner.single {
  display: none;
} */

/* single coloring */

/* .keyboard-button-inner.inner-0-2.text-unexplored {
  color: var(--unexplored-text-color-0-2); */
  /* text-shadow: 0 0 4px red; */
  /* text-shadow: -2px -2px 5px red; */
/* } */

/* .keyboard-button-inner.inner-1-2.text-unexplored {
  color: var(--unexplored-text-color-1-2); */
  /* text-shadow: 0 0 4px blue; */
  /* text-shadow: 3px 3px 5px blue; */
/* } */


.keyboard-button.wide {
  flex: 0 1 calc(15% - 6px);
  background-color: var(--unexplored-bg);
  justify-content: center;
  align-items: center;
  font-size: 10pt;
}

.keyboard-explainer-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  margin: 5px 0;
  /* background-color: green; */
}

.keyboard-explainer-letter-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  flex: 0;
  padding: 0 20px;
}

.keyboard-explainer-letter-wrapper .keyboard-button {
  flex: 0 1 100%;
  width: 38px;
  height: 58px;
}

.keyboard-explainer-text {
  flex: 1;
  text-align: left;
}

.bottomElement {
  display: flex;
  flex: 1;
}

.modal.hide {
  display: none;
}

.modal.show, .modal .shade {
  position: fixed;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: var(--main-bg);
  z-index: 2;
}

.modal.rules.show {
  justify-content: center;
  background: none;
}

.modal.rules .shade {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.rules.show .main-column {
  background-color: var(--header-secondary-color);
  width: 90vw;
  max-width: 450px;
  min-height: calc(100vh - 10vw);
  height: calc(100vh - 50px);
  border-radius: 10px;
  overflow: auto;
  z-index: 3;
}

.modal-content {
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  /* font-size: 14pt; */
  /* grid-gap: 20px; */
}

.modal-content p {
  text-align: left;
  margin: 0.5em 0;
  padding: 0 5px;
}

.settings-row {
  flex: 0;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  border-bottom: 1px solid var(--rule-color);
  padding: 10px 0px;
}

.spacer {
  flex: 1;
}

.version-row {
  flex: 0;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 10px 0px;
}

.version-row .left {
  flex: 1;
  text-align: left;
}

.version-row .right {
  flex: 1;
  text-align: right;
}

/* .settings-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  border-bottom: 1px solid var(--rule-color);
  padding: 10px 0px;
} */

.settings-row.continues {
  border-bottom: none;
  /* padding-bottom: 0; */
}

.settings-row.continued {
  border: 1px solid var(--rule-color);
  border-radius: 8px;
  padding: 10px;
  margin: 0 20px;
}

.game-over-row {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  padding: 14px 0px;
}

.big-button {
  flex: 1;
  margin: 0 5%;
  height: 36px;
  /* border: 1px solid #dddddd; */
  border: 0;
  background-color: var(--unexplored-bg);
  border-radius: 8px;
  font-size: 14px;
  color: var(--unexplored-text-color);
  /* font-weight: bold; */
}

.big-button.share-success {
  color: white;
  background-color: var(--hit-bg);
  font-weight: bold;
}

.big-button.share-error {
  color: var(--past-square-text-color);
  background-color: var(--give-up-bg);
}


.daily-selector {
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  font-size: 10pt;
  font-weight: bold;
  letter-spacing: 0.75em;
}

.daily-selector-title {
  flex: 3;
}

.daily-option {
  flex: 1;
  padding: 0 2px;
  margin: 0 5px;
  height: 24px;
}

.show-banks-row {
  display: flex;
  /* align-self: stretch; */
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.show-banks-button {
  flex: 0.5;
  align-self:center;
  padding: 2px;
  border: 0;
  border-radius: 4px;
  background: none;
  /* border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: var(--unexplored-bg); */
  font-size: 12pt;
  /* font-weight: bold; */
  color: var(--unexplored-bg);
  background-color: #80808040;
}

/* .daily-option.left {
  text-align: right;
}

.daily-option.right {
  text-align: left;
} */

@media screen and (max-width: 350px) {
  .keyboard-button.wide {
    font-size: 8pt;
  }
}

.settings-row label {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  flex: 1;
  gap: 20px;
}

.settings-row .setting-label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  text-align: left;
}

.setting-name {
  font-size: 16px;
  font-weight: bold;
}

.setting-description {
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; */
  font-size: 10pt;
}

.settings-row .switch {
  display: flex;
  flex: 0;
}

.settings-row a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 0;
}

a {
  color: var(--link-color);
}

.number-input {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  flex: 0 100px;
  height: 30px;
}

.number-input button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 30px;
  /* font-weight: bold; */
  background-color: var(--unexplored-bg);
  color: var(--unexplored-text-color);
  border: 0;
  border-radius: 4px;
  font-size: 24px;
  padding: 0;
}

.number-input button.off {
  /* visibility: hidden; */
  background-color: var(--keyboard-off-bg);
  color: var(--keyboard-off-text-color);
}

.number-input .number-input-value {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 40px;
}

.icon1 {
  width: 256px;
  height: 256px;
  background: linear-gradient(112.5deg, var(--header-main-color) 0%, var(--header-main-color) 50%, var(--header-secondary-color) 50%, var(--header-secondary-color) 100%);
  color: var(--hit-bg);
  font-size: 250px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: Brush Script MT;
}

.icon2, .icon3 {
  width: 256px;
  height: 256px;
  /* background: linear-gradient(112.5deg, var(--header-main-color) 0%, var(--header-main-color) 50%, var(--header-secondary-color) 50%, var(--header-secondary-color) 100%); */
  /* background: linear-gradient(112.5deg, var(--hit-bg) 0%, var(--hit-bg) 50%, var(--close-bg) 50%, var(--close-bg) 100%); */
  background: linear-gradient(112.5deg, var(--header-main-color) 0%, var(--header-main-color) 50%, var(--hit-bg) 50%, var(--hit-bg) 100%);
  /* color: var(--hit-bg); */
  /* font-size: 250px; */
  font-size: 225px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  /* gap: -10px; */
  justify-content: stretch;
  align-items: stretch;
  overflow: hidden;
  /* font-family: Brush Script MT; */
  /* letter-spacing: -14px; */
}

.icon3 {
  background: linear-gradient(112.5deg, var(--header-main-color) 0%, var(--header-main-color) 34%, var(--hit-bg) 34%, var(--hit-bg) 66%, var(--header-secondary-color) 66%, var(--header-secondary-color) 100%); 
}

.icon2top, .icon2bottom {
  min-height: 32px;
  flex: 0;
  /* background: var(--hit-bg); */
  background-color: #00ff0080;
}

.icon2top {
  background-color: var(--close-bg);
}

.icon2bottom {
  background-color: var(--hit-bg);
}

.icon2text, .icon3text {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon2text > div {
  /* transform: translate(-0.025em, 0); */
  /* padding-right: -14px; */
  /* background:#ff000080; */
  margin: -130px -5px -50px -5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon3text > div {
  /* margin: -130px -8px -50px -8px; */
  /* margin: -130px 0 -50px 0; */
  margin: -122px 5px -58px -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: var(--hit-bg); */
  color: var(--unexplored-text-color);
  text-shadow: 0 0 8px var(--header-main-color);
}

.icon2text > div {
  color: white;
  mix-blend-mode: difference;
}

.icon3o {
  color: var(--header-secondary-color);
}

.icon3r {
  color: var(--header-main-color);
}

/* begin generated style */

.letter-progress-tile.hit-hit {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) 10%, var(--hit-bg) 50%);
}

.letter-progress-tile.hit-close {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) 10%, var(--close-bg) 50%);
}

.letter-progress-tile.hit-miss {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) 10%, var(--miss-bg) 50%);
}

.letter-progress-tile.hit-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) 10%, var(--unexplored-bg) 50%);
}

.letter-progress-tile.close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) 10%, var(--hit-bg) 50%);
}

.letter-progress-tile.close-close {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) 10%, var(--close-bg) 50%);
}

.letter-progress-tile.close-miss {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) 10%, var(--miss-bg) 50%);
}

.letter-progress-tile.close-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) 10%, var(--unexplored-bg) 50%);
}

.letter-progress-tile.miss-hit {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) 10%, var(--hit-bg) 50%);
}

.letter-progress-tile.miss-close {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) 10%, var(--close-bg) 50%);
}

.letter-progress-tile.miss-miss {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) 10%, var(--miss-bg) 50%);
}

.letter-progress-tile.miss-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) 10%, var(--unexplored-bg) 50%);
}

.letter-progress-tile.unexplored-hit {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg) 10%, var(--hit-bg) 50%);
}

.letter-progress-tile.unexplored-close {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg) 10%, var(--close-bg) 50%);
}

.letter-progress-tile.unexplored-miss {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg) 10%, var(--miss-bg) 50%);
}

.letter-progress-tile.unexplored-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg) 10%, var(--unexplored-bg) 50%);
}

.keyboard-button.major-major {
  --split-0-2: 0%;
  --split-1-2: 50%;
  --split-2-2: 100%;
}

.keyboard-button.major-minor {
  --split-0-2: 0%;
  --split-1-2: 80%;
  --split-2-2: 100%;
}

.keyboard-button.minor-major {
  --split-0-2: 0%;
  --split-1-2: 20%;
  --split-2-2: 100%;
}

.keyboard-button.minor-minor {
  --split-0-2: 0%;
  --split-1-2: 50%;
  --split-2-2: 100%;
}

.keyboard-button.major-major-major {
  --split-0-3: 0%;
  --split-1-3: 33%;
  --split-2-3: 66%;
  --split-3-3: 100%;
}

.keyboard-button.major-major-minor {
  --split-0-3: 0%;
  --split-1-3: 44%;
  --split-2-3: 88%;
  --split-3-3: 100%;
}

.keyboard-button.major-minor-major {
  --split-0-3: 0%;
  --split-1-3: 44%;
  --split-2-3: 55%;
  --split-3-3: 100%;
}

.keyboard-button.major-minor-minor {
  --split-0-3: 0%;
  --split-1-3: 66%;
  --split-2-3: 83%;
  --split-3-3: 100%;
}

.keyboard-button.minor-major-major {
  --split-0-3: 0%;
  --split-1-3: 11%;
  --split-2-3: 55%;
  --split-3-3: 100%;
}

.keyboard-button.minor-major-minor {
  --split-0-3: 0%;
  --split-1-3: 16%;
  --split-2-3: 83%;
  --split-3-3: 100%;
}

.keyboard-button.minor-minor-major {
  --split-0-3: 0%;
  --split-1-3: 16%;
  --split-2-3: 33%;
  --split-3-3: 100%;
}

.keyboard-button.minor-minor-minor {
  --split-0-3: 0%;
  --split-1-3: 33%;
  --split-2-3: 66%;
  --split-3-3: 100%;
}

.keyboard-button.hit-hit {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-2), var(--hit-bg) var(--split-1-2), var(--hit-bg) var(--split-1-2), var(--hit-bg) var(--split-2-2));
}

.keyboard-button.hit-close {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-2), var(--hit-bg) var(--split-1-2), var(--close-bg) var(--split-1-2), var(--close-bg) var(--split-2-2));
}

.keyboard-button.hit-miss {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-2), var(--hit-bg) var(--split-1-2), var(--miss-bg) var(--split-1-2), var(--miss-bg) var(--split-2-2));
}

.keyboard-button.hit-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-2), var(--hit-bg) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-2-2));
}

.keyboard-button.close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-2), var(--close-bg) var(--split-1-2), var(--hit-bg) var(--split-1-2), var(--hit-bg) var(--split-2-2));
}

.keyboard-button.close-close {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-2), var(--close-bg) var(--split-1-2), var(--close-bg) var(--split-1-2), var(--close-bg) var(--split-2-2));
}

.keyboard-button.close-miss {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-2), var(--close-bg) var(--split-1-2), var(--miss-bg) var(--split-1-2), var(--miss-bg) var(--split-2-2));
}

.keyboard-button.close-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-2), var(--close-bg) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-2-2));
}

.keyboard-button.miss-hit {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-2), var(--miss-bg) var(--split-1-2), var(--hit-bg) var(--split-1-2), var(--hit-bg) var(--split-2-2));
}

.keyboard-button.miss-close {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-2), var(--miss-bg) var(--split-1-2), var(--close-bg) var(--split-1-2), var(--close-bg) var(--split-2-2));
}

.keyboard-button.miss-miss {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-2), var(--miss-bg) var(--split-1-2), var(--miss-bg) var(--split-1-2), var(--miss-bg) var(--split-2-2));
}

.keyboard-button.miss-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-2), var(--miss-bg) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-2-2));
}

.keyboard-button.unexplored-hit {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-2) var(--split-0-2), var(--unexplored-bg-0-2) var(--split-1-2), var(--hit-bg) var(--split-1-2), var(--hit-bg) var(--split-2-2));
}

.keyboard-button.unexplored-close {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-2) var(--split-0-2), var(--unexplored-bg-0-2) var(--split-1-2), var(--close-bg) var(--split-1-2), var(--close-bg) var(--split-2-2));
}

.keyboard-button.unexplored-miss {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-2) var(--split-0-2), var(--unexplored-bg-0-2) var(--split-1-2), var(--miss-bg) var(--split-1-2), var(--miss-bg) var(--split-2-2));
}

.keyboard-button.unexplored-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-2) var(--split-0-2), var(--unexplored-bg-0-2) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-1-2), var(--unexplored-bg-1-2) var(--split-2-2));
}

.keyboard-button.hit-hit-hit {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.hit-hit-close {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.hit-hit-miss {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.hit-hit-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.hit-close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.hit-close-close {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.hit-close-miss {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.hit-close-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.hit-miss-hit {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.hit-miss-close {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.hit-miss-miss {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.hit-miss-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.hit-unexplored-hit {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.hit-unexplored-close {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.hit-unexplored-miss {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.hit-unexplored-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--hit-bg) var(--split-0-3), var(--hit-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.close-hit-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.close-hit-close {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.close-hit-miss {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.close-hit-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.close-close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.close-close-close {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.close-close-miss {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.close-close-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.close-miss-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.close-miss-close {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.close-miss-miss {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.close-miss-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.close-unexplored-hit {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.close-unexplored-close {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.close-unexplored-miss {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.close-unexplored-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--close-bg) var(--split-0-3), var(--close-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.miss-hit-hit {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.miss-hit-close {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.miss-hit-miss {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.miss-hit-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.miss-close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.miss-close-close {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.miss-close-miss {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.miss-close-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.miss-miss-hit {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.miss-miss-close {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.miss-miss-miss {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.miss-miss-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.miss-unexplored-hit {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.miss-unexplored-close {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.miss-unexplored-miss {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.miss-unexplored-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--miss-bg) var(--split-0-3), var(--miss-bg) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.unexplored-hit-hit {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.unexplored-hit-close {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.unexplored-hit-miss {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.unexplored-hit-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--hit-bg) var(--split-1-3), var(--hit-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.unexplored-close-hit {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.unexplored-close-close {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.unexplored-close-miss {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.unexplored-close-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--close-bg) var(--split-1-3), var(--close-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.unexplored-miss-hit {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.unexplored-miss-close {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.unexplored-miss-miss {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.unexplored-miss-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--miss-bg) var(--split-1-3), var(--miss-bg) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

.keyboard-button.unexplored-unexplored-hit {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--hit-bg) var(--split-2-3), var(--hit-bg) var(--split-3-3));
}

.keyboard-button.unexplored-unexplored-close {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--close-bg) var(--split-2-3), var(--close-bg) var(--split-3-3));
}

.keyboard-button.unexplored-unexplored-miss {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--miss-bg) var(--split-2-3), var(--miss-bg) var(--split-3-3));
}

.keyboard-button.unexplored-unexplored-unexplored {
  background: linear-gradient(var(--keyboard-angle), var(--unexplored-bg-0-3) var(--split-0-3), var(--unexplored-bg-0-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-1-3), var(--unexplored-bg-1-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-2-3), var(--unexplored-bg-2-3) var(--split-3-3));
}

/* end generated style */

.keyboard-button:active {
  /* background-color: #00FF00; */
  opacity: 50%;
}